// 引用两个插件
import LiveMatrixhtml2canvas from "html2canvas"; // 转为图片
import LiveMatrixprintJS from "print-js"; // 打印
import disableScroll from "disable-scroll";

export default {
    name: "LiveMatrix",
    created() {
        // this.$$.navbarAnimation()
        this.$nextTick(function () {
            //   localStorage.clear();
            // 获取underlying ric
            this.GetUnderlyingList();
            // 获得ric
            this.getRiclist();
            // 搜索框出现
            this.ariseCombobox("#comboboxTotal");
            // localStorage.removeItem("mibbPriceMatrix");
            if (localStorage.getItem("mibbPriceMatrix") !== null) {
                let _this = this,
                    lengthNum = 0;
                this.selectedRic = localStorage.getItem("mibbPriceMatrix").split(",");
                lengthNum = this.selectedRic.filter(function (tiem) {
                    return tiem !== "";
                }).length;
                if (lengthNum <= 2) {
                    this.MatixData = Array(2);
                } else if (lengthNum > 2 && lengthNum < 5) {
                    this.MatixData = Array(4);
                } else if (lengthNum >= 5) {
                    this.MatixData = Array(6);
                    $(".mainContent")
                        .find(".scope")
                        .find("button")
                        .hide();
                }
                $.each(this.MatixData, (i, c) => {
                    setTimeout(() => {
                        this.ariseCombobox("#comboboxTotal" + i, i);
                        $("#comboboxTotal" + i)
                            .parent()
                            .find("input")
                            .val("");
                    }, 10);
                });

                $.each(this.selectedRic, function (index, con) {
                    if (con) {
                        _this.getRicdatas(con, index);
                    }
                });
            } else {
                for (let i = 0, max = this.MatixData; i < max.length; i++) {
                    this.ariseCombobox("#comboboxTotal" + i, i);
                }
            }
        });
    },
    mounted() {
        this.$nextTick(function () { });
    },
    data() {
        return {
            conditionData: [
                {
                    icon: require("../../../../../public/image/Daily_Highlight.png"),
                    name: this.$t("int.DH"),
                    hint:
                        "<strong>" +
                        this.$t("int.DH") +
                        ":</strong> " +
                        this.$t("int.Stcitm")
                },
                {
                    icon: require("../../../../../public/image/More_Responsive.png"),
                    name: this.$t("int.MR"),
                    hint:
                        "<strong>" +
                        this.$t("int.MR") +
                        ": </strong> " +
                        this.$t("int.MSsmou")
                },
                {
                    icon: require("../../../../../public/image/Low_Time_Decay.png"),
                    name: this.$t("int.LTD"),
                    hint:
                        "<strong>" +
                        this.$t("int.LTD") +
                        ":</strong> " +
                        this.$t("int.MSmtd")
                },
                {
                    icon: require("../../../../../public/image/Low_Inventory.png"),
                    name: this.$t("int.LI"),
                    hint:
                        "<strong>" +
                        this.$t("int.LI") +
                        ":</strong> " +
                        this.$t("int.MScMa")
                },
                {
                    icon: require("../../../../../public/image/Near_Expiry.png"),
                    name: this.$t("int.NE"),
                    hint:
                        "<strong>" +
                        this.$t("int.NE") +
                        ":</strong> " +
                        this.$t("int.Ddtdb")
                },
                {
                    icon: require("../../../../../public/image/Low_DW_Price.png"),
                    name: this.$t("int.LDP"),
                    hint:
                        "<strong>" +
                        this.$t("int.LDP") +
                        ":</strong> " +
                        this.$t("int.DbbT")
                },
            ],
            MatixData: Array(2),
            ricData: [],
            underlyingRic: [],
            // 已选的ric
            selectedRic: Array(6),
            issuer_mibb: false,
            Results: 0,
            viewFT: false,
            reminder: true,
        };
    },
    computed: {},
    watch: {},
    methods: {
        // 获取 underlying ric
        GetUnderlyingList: function () {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetUnderlyingList",
                data: { token: "webkey", issuers: "MST" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        this.underlyingRic = data.underlying_name;
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 underlying 的数据
        GetRICListByUnderlying: function (underlying, label) {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICListByUnderlying",
                data: { token: "webkey", issuers: "MST", underlying: underlying },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        $("#comboboxTotal")
                            .parent()
                            .find("input")
                            .val(label);
                        if (
                            data.ric.length <= 2 &&
                            this.MatixData.length !== 4 &&
                            this.MatixData.length !== 6
                        ) {
                            this.MatixData = Array(2);
                        } else if (
                            data.ric.length > 2 &&
                            this.MatixData.length !== 6 &&
                            data.ric.length < 5
                        ) {
                            this.MatixData = Array(4);
                        } else if (data.ric.length <= 5) {
                            this.MatixData = Array(6);
                        }
                        $.each(this.MatixData, (i, c) => {
                            setTimeout(() => {
                                this.ariseCombobox("#comboboxTotal" + i, i);
                                $("#comboboxTotal" + i)
                                    .parent()
                                    .find("input")
                                    .val("");
                            }, 10);
                        });
                        $.each(data.ric, (i, c) => {
                            if (i <= 8) {
                                this.selectedRic[i] = c.ric;
                                this.getRicdatas(c.ric, i);
                            }
                        });
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        /*获取ric list*/
        getRiclist: function () {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICList",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        let _this = this;
                        this.ricData = data.ric.filter(item => {
                            return _this.$$.isMAYBANK(item.issuer_name);
                        });
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        /*获取当前ric数据*/
        getRicdatas: function (ric, index) {
            this.MatixData[index] = {
                theirdata: [
                    {
                        name: this.$t("lima.EbG"),
                        price: ""
                    },
                    {
                        name: this.$t("int.Se"),
                        price: ""
                    },
                    {
                        name: this.$t("lima.TD"),
                        price: ""
                    },
                    {
                        name: this.$t("lima.DtLTD"),
                        price: ""
                    },
                    {
                        name: this.$t("int.IV"),
                        price: ""
                    },
                    {
                        name: this.$t("lima.uN"),
                        price: ""
                    },
                    {
                        name: this.$t('hp.Ty'),
                        price: ""
                    },
                    {
                        name: this.$t("hp.EbP"),
                        price: ""
                    },
                    {
                        name: this.$t("hp.EbR"),
                        price: ""
                    },
                    {
                        name: this.$t("WSe.ED"),
                        price: ""
                    },


                ],
                livematrix: [],
                dsplyRic: "",
                ticker: '',
                tickerN: '',
                dsply_nmll: '',
                underlying_bid: '',
                underlyingRic: '',
                publishTime: "",
                type: false,
                conditionIcon: [
                    {
                        icon: require("../../../../../public/image/Daily_Highlight.png"),
                        visible: 0,
                        name:
                            "<strong>" +
                            this.$t("int.DH") +
                            ":</strong> " +
                            this.$t("int.Stcitm")
                    },
                    {
                        icon: require("../../../../../public/image/More_Responsive.png"),
                        visible: 0,
                        name:
                            "<strong>" +
                            this.$t("int.MR") +
                            ": </strong> " +
                            this.$t("int.MSsmou")
                    },
                    {
                        icon: require("../../../../../public/image/Low_Time_Decay.png"),
                        visible: 0,
                        name:
                            "<strong>" +
                            this.$t("int.LTD") +
                            ":</strong> " +
                            this.$t("int.MSmtd")
                    },
                    {
                        icon: require("../../../../../public/image/Low_Inventory.png"),
                        visible: 0,
                        name:
                            "<strong>" +
                            this.$t("int.LI") +
                            ":</strong> " +
                            this.$t("int.MScMa")
                    },
                    {
                        icon: require("../../../../../public/image/Near_Expiry.png"),
                        visible: 0,
                        name:
                            "<strong>" +
                            this.$t("int.NE") +
                            ":</strong> " +
                            this.$t("int.Ddtdb")
                    },
                    {
                        icon: require("../../../../../public/image/Low_DW_Price.png"),
                        visible: 0,
                        name:
                            "<strong>" +
                            this.$t("int.LDP") +
                            ":</strong> " +
                            this.$t("int.DbbT")
                    }
                ]
            };
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetPriceMatrixData",
                data: { token: "webkey", ric: ric },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        this.MatixData[index].conditionIcon[0].visible =
                            data.daily_highlights;
                        this.MatixData[index].conditionIcon[1].visible =
                            data.ric_data.more_responsive;
                        this.MatixData[index].conditionIcon[2].visible =
                            data.ric_data.low_time_decay;
                        this.MatixData[index].conditionIcon[3].visible = data.soldout;
                        this.MatixData[index].conditionIcon[4].visible =
                            data.ric_data.near_expiry;
                        this.MatixData[index].conditionIcon[5].visible =
                            data.ric_data.low_dw_price;
                        this.MatixData[index].theirdata[0].price = data.ric_data.effective_gearing !== "N/A"
                            ? this.$$.formatNumber(data.ric_data.effective_gearing, 1, 0) +
                            " x"
                            : "N/A";
                        console.log(data.ric_data.sensitivity);
                        if (this.$$.formatNumber(data.ric_data.sensitivity, 0, 1) > 1) {
                            this.MatixData[index].theirdata[1].price = data.ric_data.sensitivity !== "N/A"
                                ? this.$$.formatNumber(data.ric_data.sensitivity, 1, 0) + " " + this.$t("WTs.ticks")
                                : "N/A";
                        } else {
                            this.MatixData[index].theirdata[1].price = data.ric_data.sensitivity !== "N/A"
                                ? this.$$.formatNumber(data.ric_data.sensitivity, 1, 0) + " " + this.$t("WTs.tick")
                                : "N/A";
                        }

                        this.MatixData[index].theirdata[2].price = this.$$.formatNumber(data.ric_data.theta_pct, 1, 0) > 1 ? this.$$.formatNumber(data.ric_data.theta_pct, 1, 0) +' '+this.$t('WSe.da'):this.$$.formatNumber(data.ric_data.theta_pct, 1, 0) +' '+this.$t('WSe.day')
                        this.MatixData[index].theirdata[3].price = data.ric_data.TimeToMaturity + " " + this.$t("WSe.da")
                        this.MatixData[index].theirdata[4].price = data.ric_data.implied_volatility !== "N/A"
                            ? this.$$.formatNumber(data.ric_data.implied_volatility, 1, 0) +
                            " %"
                            : "N/A";
                        this.MatixData[index].theirdata[5].price = data.ric_data.underlying_name
                        this.MatixData[index].theirdata[6].price = data.ric_data.type
                        this.MatixData[index].theirdata[7].price = data.ric_data.exercise_price !== "N/A"
                            ?
                            data.ric_data.underlying_curr + ' ' +
                            this.$$.kilobitNumber(
                                this.$$.formatNumber(data.ric_data.exercise_price, 3, 0)
                            )
                            : "N/A";
                        this.MatixData[index].theirdata[8].price = data.ric_data.conv_ratio !== "N/A"
                            ? data.ric_data.conv_ratio + ' : 1'
                            : "N/A";
                        this.MatixData[index].theirdata[9].price = data.ric_data.LSTTRDDATE;
                        this.MatixData[index].ticker = data.ric_data.underlying_ticker
                        this.MatixData[index].tickerN = data.ric_data.ticker
                        this.MatixData[index].underlyingRic = data.ric_data.underlying_ric
                        this.MatixData[index].dsply_nmll = data.ric_data.dsply_nmll
                        this.MatixData[index].underlying_bid = data.ric_data.underlying_bid
                        this.MatixData[index].livematrix = data.livematrix

                        $.each(this.MatixData[index].livematrix, (l, d) => {
                            d.tableArr = []
                            for (let i = 1; i <= 5; i++) {
                                d.tableArr.push({
                                    bid: d['bid' + i],
                                    date: new Date(d['date' + i]).toUTCString().split(' ')[1] + '-' + new Date(d['date' + i]).toUTCString().split(' ')[2]
                                })
                            }
                        })
                        this.MatixData[index].livematrix = this.isRepeat(
                            data.livematrix,
                            data.ric_data.underlying_bid
                        )
                        this.MatixData[index].dsplyRic = data.ric_data.ric;
                        if (
                            data.reliable_livematrix == 0 &&
                            this.$$.isMAYBANK(data.ric_data.issuer_name)
                        ) {
                            this.issuer_mibb = true;
                        } else {
                            this.issuer_mibb = false;
                            this.MatixData[index].publishTime =
                                data.livematrix.length === 0
                                    ? ""
                                    : data.livematrix[0].publish_time;
                        }
                        this.MatixData[index].type =
                            data.ric_data.type == "PUT" ? true : false;

                        this.Results = this.selectedRic.filter(tiem => {
                            return tiem !== "";
                        }).length;
                        if (this.MatixData.length == 6) {
                            $(".mainContent")
                                .find(".scope")
                                .find("button")
                                .hide();
                        } else {
                            $(".mainContent")
                                .find(".scope")
                                .find("button")
                                .show();
                        }
                        this.$forceUpdate();
                        localStorage.removeItem("mibbPriceMatrix");
                        localStorage.setItem("mibbPriceMatrix", this.selectedRic.join(","));
                        $.each(this.MatixData, (i, c) => {
                            setTimeout(() => {
                                this.ariseCombobox("#comboboxTotal" + i, i);
                                if (c !== undefined) {
                                    $("#datalist" + i)
                                        .find("input")
                                        .val(
                                            c.tickerN
                                        );
                                    $(".dataContent>li")
                                        .eq(i)
                                        .find(".tableBox")
                                        .slideDown();
                                    $(".dataContent>li")
                                        .eq(i)
                                        .find(".AttributesBox")
                                        .slideDown();
                                    $(".dataContent>li")
                                        .eq(i)
                                        .find(".ChartButton")
                                        .slideDown();
                                }
                            }, 10);
                        });
                        this.$forceUpdate()
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 判断数组下一个是否与上一个相等
        isRepeat: function (arr, bid) {
            let hash = {};
            let ifv = false;
            for (let i in arr) {
                hash[arr[i].bid] = true;
                if (arr[i].underlying_bid == bid) {
                    arr[i].bidIfv = true;
                } else {
                    arr[i].bidIfv = false;
                }
            }
            return arr;
        },
        timeLivematrixData: function (D, underlying_bid, data, i) {
            data.tableArr = []
            $("#liveMatrix").find('#datalist' + i)
                .find(".ChartButtonBox")
                .find("div")
                .eq(D - 1)
                .find(".btn")
                .addClass("mark");
            $("#liveMatrix").find('#datalist' + i)
                .find(".ChartButtonBox")
                .find("div")
                .eq(D - 1)
                .siblings("div")
                .find(".btn")
                .removeClass("mark")
            if (D == 1) {
                data.tableArr[0] = {
                    livematrix: this.isRepeat(
                        data.liveArr5,
                        underlying_bid
                    )
                }
            }
            if (D == 2) {
                for (let i = 0; i < D; i++) {
                    data.tableArr[i] = {
                        livematrix: this.isRepeat(
                            data['liveArr' + (i + 4)],
                            underlying_bid
                        )
                    }
                }

            }
            if (D == 3) {
                for (let i = 0; i < D; i++) {
                    data.tableArr[i] = {
                        livematrix: this.isRepeat(
                            data['liveArr' + (i + 3)],
                            underlying_bid
                        )
                    }
                }
            }
            if (D == 4) {
                for (let i = 0; i < D; i++) {
                    data.tableArr[i] = {
                        livematrix: this.isRepeat(
                            data['liveArr' + (i + 2)],
                            underlying_bid
                        )
                    }
                }
            }
            if (D == 5) {
                for (let i = 0; i < D; i++) {
                    data.tableArr[i] = {
                        livematrix: this.isRepeat(
                            data['liveArr' + (i + 1)],
                            underlying_bid
                        )
                    }
                }
            }
            this.$forceUpdate()
        },
        // 重置
        info: function () {
            let timer1;
            let _this = this,
                lengthNum = 0;
            lengthNum = this.selectedRic.filter(function (tiem) {
                return tiem !== "";
            }).length;
            if (lengthNum <= 2) {
                this.MatixData = Array(2);
            } else if (lengthNum > 2 && lengthNum < 5) {
                this.MatixData = Array(4);
            } else if (lengthNum >= 5) {
                this.MatixData = Array(6);
            }
            $.each(this.MatixData, (i, c) => {
                setTimeout(() => {
                    this.ariseCombobox("#comboboxTotal" + i, i);
                    $("#comboboxTotal" + i)
                        .parent()
                        .find("input")
                        .val("");
                }, 10);
            });
            $.each(this.selectedRic, function (index, con) {
                if (con) {
                    _this.getRicdatas(con, index);
                }
            });
            $("#info").attr("disabled", true);
            clearTimeout(timer1);
            //设置一次性定时器
            timer1 = setTimeout(function () {
                $("#info").attr("disabled", false);
            }, 5000);
        },
        /*下载table*/
        printTable: function () {
            var arr = this.MatixData;
            var divBoxArr = [];
            arr.forEach(function (it, index) {
                if (it.dsply_nmll !== "") {
                    /*判断哪一个panel有存储*/
                    divBoxArr.push({
                        div: "#datalist" + index,
                        name: it.dsply_nmll
                    });
                }
            });
            this.$$.MatrixExport_table(divBoxArr, this.$$.export_name("live_matrix"));
        },

        /*打印table*/
        correlationPrintTbale: function () {
            $("#printBox").show();
            $(".performance>li")
                .find("h3")
                .hide();
            $.each($("#liveMatrix").find(".dataContent>li"), (i, c) => {
                if (i <= 2) {
                    $("#printBox")
                        .find(".dataContent")
                        .eq(0)
                        .append($(c).clone());
                } else if (i > 2 && i <= 5) {
                    $("#printBox")
                        .find(".dataContent")
                        .eq(1)
                        .append($(c).clone());
                } else if (i > 5) {
                    $("#printBox")
                        .find(".dataContent")
                        .eq(2)
                        .append($(c).clone());
                }
            });

            $("#printBox")
                .find(".header")
                .append($(".headerpart").clone());
            $("#printBox")
                .find(".mainContent>.scope")
                .append($(".first").clone());
            $("#printBox")
                .find(".mainContent>.scope")
                .append($(".condition").clone());
            $("#printBox")
                .find(".buttonBox")
                .append($(".mainContent>.scope>.btn").clone());
            $("#printBox")
                .find(".bottom")
                .append($(".bottom-disclaimer").clone());

            $("#contentBox").hide();
            let arr = [];
            window.scrollTo(0, 0);
            disableScroll.on();

            LiveMatrixhtml2canvas(this.$refs.printheader, {
                backgroundColor: "#ffffff",
                useCORS: true,
                height: this.$refs.printheader.scrollHeight,
                width: this.$refs.printheader.scrollWidth
            }).then(canvas => {
                arr[0] = canvas.toDataURL("image/png");

                LiveMatrixhtml2canvas(this.$refs.printmainContent, {
                    backgroundColor: "#ffffff",
                    useCORS: true,
                    height: this.$refs.printmainContent.scrollHeight,
                    width: this.$refs.printmainContent.scrollWidth
                }).then(canvas => {
                    arr[1] = canvas.toDataURL("image/png");
                    LiveMatrixhtml2canvas(this.$refs.printmatrix0, {
                        backgroundColor: "#ffffff",
                        useCORS: true,
                        height: this.$refs.printmatrix0.scrollHeight,
                        width: this.$refs.printmatrix0.scrollWidth
                    }).then(canvas => {
                        arr[2] = canvas.toDataURL("image/png");

                        LiveMatrixhtml2canvas(this.$refs.printmatrix1, {
                            backgroundColor: "#ffffff",
                            useCORS: true,
                            height: this.$refs.printmatrix1.scrollHeight,
                            width: this.$refs.printmatrix1.scrollWidth
                        }).then(canvas => {
                            arr[3] = canvas.toDataURL("image/png");

                            LiveMatrixhtml2canvas(this.$refs.printmatrix2, {
                                backgroundColor: "#ffffff",
                                useCORS: true,
                                height: this.$refs.printmatrix2.scrollHeight,
                                width: this.$refs.printmatrix2.scrollWidth
                            }).then(canvas => {
                                arr[4] = canvas.toDataURL("image/png");
                                LiveMatrixhtml2canvas(this.$refs.printbuttonBox, {
                                    backgroundColor: "#ffffff",
                                    useCORS: true,
                                    height: this.$refs.printbuttonBox.scrollHeight,
                                    width: this.$refs.printbuttonBox.scrollWidth
                                }).then(canvas => {
                                    arr[5] = canvas.toDataURL("image/png");
                                    LiveMatrixhtml2canvas(this.$refs.printbottom, {
                                        backgroundColor: "#ffffff",
                                        useCORS: true,
                                        height: this.$refs.printbottom.scrollHeight,
                                        width: this.$refs.printbottom.scrollWidth
                                    }).then(canvas => {
                                        arr[6] = canvas.toDataURL("image/png");

                                        var printstr = "";
                                        printstr += '<img width="100%" src="' + arr[0] + '"/>';
                                        printstr += '<img width="100%" src="' + arr[1] + '"/>';
                                        printstr += '<img width="100%" src="' + arr[2] + '"/>';
                                        printstr += '<img width="100%" src="' + arr[3] + '"/>';
                                        printstr += '<img width="100%" src="' + arr[4] + '"/>';
                                        printstr += '<img width="100%" src="' + arr[5] + '"/>';
                                        printstr += '<img width="100%" src="' + arr[6] + '"/>';
                                        LiveMatrixprintJS({
                                            printable: printstr,
                                            type: "raw-html",
                                            onLoadingStart: function () {
                                                $("#contentBox").show();
                                                $("#printBox").hide();
                                                $("#printBox")
                                                    .find(".dataContent")
                                                    .empty();
                                                $("#printBox")
                                                    .find(".header")
                                                    .empty();
                                                $("#printBox")
                                                    .find(".mainContent>.scope")
                                                    .empty();
                                                $("#printBox")
                                                    .find(".buttonBox")
                                                    .empty();
                                                $("#printBox")
                                                    .find(".bottom")
                                                    .empty();
                                                disableScroll.off();
                                            }
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        },
        // 出现combobox
        ariseCombobox: function (id, index) {
            let _this = this;
            this.$$.jqcombobox(id);
            $(id).combobox({
                select: function (event, ui) {
                    if (id == "#comboboxTotal") {
                        _this.GetRICListByUnderlying(this.value, ui.item.label);
                        _this.selectedRic = Array(6);
                        _this.$$.inputEmpty($(id).parent());
                        $(".dataContent>li")
                            .find(".tableBox")
                            .slideUp();
                        $(".dataContent>li")
                            .find(".AttributesBox")
                            .slideUp();
                        $(".dataContent>li")
                            .eq(id)
                            .find(".ChartButton")
                            .slideUp();
                    } else {
                        /*获取当前ric数据*/
                        _this.getRicdatas(this.value, index);
                        // 存储已选的ric
                        _this.selectedRic[index] = this.value;
                        _this.$$.inputEmpty($(id).parent());
                        $(".dataContent>li")
                            .eq(index)
                            .find(".tableBox")
                            .slideUp();
                        $(".dataContent>li")
                            .eq(index)
                            .find(".AttributesBox")
                            .slideUp();
                        $(".dataContent>li")
                            .eq(id)
                            .find(".ChartButton")
                            .slideUp();
                    }
                }
            });
            if (id == "#comboboxTotal") {
                $(id)
                    .parent()
                    .find("input")
                    .attr("placeholder", this.$t("hp.SU"));
                // _this.$$.inputEmpty($(id).parent())
            } else {
                $(id)
                    .parent()
                    .find("input")
                    .attr("placeholder", this.$t("hp.SW"));
                _this.$$.inputEmpty($(id).parent());
            }
        },
        // 添加数据显示
        addMatixData: function () {
            let _this = this;
            if (this.MatixData.length <= 6) {
                if (this.MatixData.length == 2) {
                    this.MatixData = Array(4);
                } else if (this.MatixData.length == 4) {
                    this.MatixData = Array(6);
                    $(".mainContent")
                        .find(".scope")
                        .find("button")
                        .hide();
                }
                setTimeout(() => {
                    $.each(this.MatixData, (i, c) => {
                        this.ariseCombobox("#comboboxTotal" + i, i);
                        $("#comboboxTotal" + i)
                            .parent()
                            .find("input")
                            .val("");
                    });
                }, 10);
                $.each(this.selectedRic, function (index, con) {
                    if (con) {
                        _this.getRicdatas(con, index);
                    }
                });
            }
        },
        // 点击出详细信息
        ViewLess: function (i) {
            this.viewFT = !this.viewFT;
            if (this.viewFT) {
                $(".dataContent>li")
                    .eq(i)
                    .find(".view_less>span")
                    .text(this.$t("lima.Vm"));
            } else {
                $(".dataContent>li")
                    .eq(i)
                    .find(".view_less>span")
                    .text(this.$t("lima.Vl"));
            }
            $(".dataContent>li")
                .eq(i)
                .find(".tableBox")
                .slideToggle();
            $(".dataContent>li")
                .eq(i)
                .find(".AttributesBox")
                .slideToggle();
            $(".dataContent>li")
                .eq(i)
                .find(".ChartButton")
                .slideToggle();
        },

        onOff: function (index) {
            $('#liveMatrix').find('.one' + index).toggle()
            $('#liveMatrix').find('.whole' + index).toggle()
            if ($('#liveMatrix').find('.onOff' + index).hasClass('bi-chevron-double-right')) {
                $('#liveMatrix').find('.onOff' + index).removeClass('bi-chevron-double-right');
                $('#liveMatrix').find('.onOff' + index).addClass('bi-chevron-double-left');
                this.reminder = false

            } else {
                $('#liveMatrix').find('.onOff' + index).removeClass('bi-chevron-double-left');
                $('#liveMatrix').find('.onOff' + index).addClass('bi-chevron-double-right');
                this.reminder = true
            }
        }

    }
};
